import React, { useState, useEffect } from "react";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";



export default function PrivacyPolicy() {
 const Site_Url = "https://passportoffice.in/";
 const BASE_URL = Site_Url + "admin/API/";

 //website Setting API
 const [webSetting, setwebSetting] = useState([]);
 useEffect(() => {
   const webSettingData = async () => {
     const response = await fetch(`${BASE_URL}website_settingAPI.php`);
     const jsonData = await response.json();
     setwebSetting(jsonData);
   };
   webSettingData();
 }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <DataContent
        title="Privacy Policy"
        desc={webSetting.privacy_policy_desc}
      />
    </>
  );
}
